/* Basic editor styles */
.tiptap {
    outline: none;
    height: '100%';
    max-height: 260px;
    overflow-y: auto;
    margin-bottom: 8px;
    line-height: 24px;

    :first-child {
      margin-top: 0;
    }
      
    /* Placeholder (at the top) */
    p.is-editor-empty:first-child::before {
      color: var(--mui-palette-colors-placeholder_text);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
    .mention {
        border-radius: 4px;
        box-decoration-break: clone;
        display: inline-block;
        transform: translate(0,7px);
      }

  }
  .staticMention {
    border-radius: 4px;
    box-decoration-break: clone;
    display: inline-block;
    transform: translate(0,7px);
  }