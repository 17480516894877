.loader-component {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-spinner {
  --ml-spinner-size: 8;
  --line-color: #B7A4FB;
  --line-alpha: 1;
  --ring-color: #F5F5FF;
  --ring-size: 4;

  font-size: calc(var(--ml-spinner-size) * 1em);
  width: 1em;
  height: 1em;
  border-radius: 50%;
}

.ml-spinner .line {
  fill: none;
  stroke: var(--line-color);
  stroke-width: var(--ring-size);
  opacity: var(--line-alpha);
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate3d(0, 0, 1, 0deg);
  animation: 
    2156ms ml-spinner-arc ease-in-out infinite,
    1829ms ml-spinner-rotate linear infinite;
}
.ml-spinner .ring {
  fill: none;
  stroke: var(--ring-color);
  stroke-width: var(--ring-size);
  opacity: var(--ring-alpha);
}
@keyframes ml-spinner-rotate {
  to { transform: rotate3d(0, 0, 1, 360deg); }
}
@keyframes ml-spinner-arc {
  from { stroke-dasharray: 0 150; stroke-dashoffset: 0; }
  to { stroke-dasharray: 100 150; stroke-dashoffset: -140; }
}

